@import "src/variables";

.installationWizardContainer{
  display: block;

  ul{
    display: block;
    margin: 0;

    li{
      display: block;
      padding: 13px 10px 13px 35px;
      margin-bottom: 10px;
      background: $white;
      cursor: pointer;
      border-radius: 5px;
      transition: all 500ms ease;
      position: relative;

      &.active,
      &:hover{
        background: $bgLiteGrey;
      }

      span{
        position: absolute;
        left: 8px;
        top: 12px;
        background: transparent;
        border: 1px solid $primary;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        font-size: 12px;
        display: flex;
        font-weight: 600;
        color: $white;
        align-items: center;
        justify-content: center;

        svg{
          width: 8px;
        }
      }

      &.stepCompleted{
        span{
          background: $secondary;
          border: 1px solid $secondary;
        }
      }
    }
  }
}

.stepsContentWrapper{
  height: 100%;
  border: 1px solid #eaeaea;
  padding: 15px;
  border-radius: 5px;

  .stepInfo{
    display: none;

    &.active{
      display: block;
    }

    h4{
      margin: 0 0 15px;
    }
    p{
      margin: 0 0 15px;
    }
  }
}

.finalSteps{
  display: none;
  flex: 0 0 auto;
  margin-top: 20px;
  background: #edfaff;
  padding: 20px;
  border-radius: 5px;

  @media screen and ($xs-down){
    flex-direction: column;
  }

  &.show{
    display: flex;
  }

  .finalStepsIcon{
    width: 80px;
    height: 80px;
    margin-right: 20px;

    @media screen and ($xs-down){
      margin: 0 auto 20px;
    }
  }

  .finalStepsContent{

    p{
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}