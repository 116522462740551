@import "../../variables";

.planItemWrapper{
  background: $white;
  min-height: 400px;
  border-radius: 5px;
  padding: 20px 20px 80px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 4px 4px 20px rgba(0,0,0, .05);

  @media screen and ($md-down) {
    min-height: auto;
  }

  .planHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .planName{
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      font-family: "Roboto", sans-serif;
      color: $primary;
    }

    .planPrice{
      font-size: 24px;
      font-weight: 600;
      color: $secondary;

      span{
        font-size: 16px;
        color: $textDark;
      }
    }
  }

  .planOptions{

    ul{
      margin: 0 0 20px;
      padding: 0;

      li{
        position: relative;
        list-style: none;
        margin-bottom: 10px;
        padding-left: 25px;

        span{
          position: absolute;
          left: 0;
          top: 0;
          color: $secondary;

          svg{
            width: 15px;
          }
        }
      }
    }
  }

  .planFooter{
    position: absolute;
    bottom: 0;
    text-align: center;
    padding-bottom: 20px;
  }

  &.currentPlan{
    border: 2px solid $primary;
  }
}