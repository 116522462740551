@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700;800;900&display=swap');

@import "variables";

html {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  background-color: $white;
  font-family: $overpass;
  margin: 0;
  padding: 0;
  color: $textDark;
}

a{
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

ul,li,p{
  margin: 0;
  padding: 0;
}

:global{
  .textCenter{text-align: center;}
  .textLeft{text-align: left;}
  .textRight{text-align: right;}
  .mb1{margin-bottom: 10px;}
  .mb2{margin-bottom: 20px;}
  .mb3{margin-bottom: 30px;}
  .mb4{margin-bottom: 40px;}
  .mb5{margin-bottom: 50px;}
  .mt1{margin-top: 10px;}
  .mt2{margin-top: 20px;}
  .mt3{margin-top: 30px;}
  .mt4{margin-top: 40px;}
  .mt5{margin-top: 50px;}
  .popupTable{width: 100%;}
  .noticeBadge{
    padding: 20px;
    margin: 20px 0 0 0;
    border: 1px solid #cccccc;
    background: #fafafa;
    text-align: left;
    color: $textDark;
    line-height: 1;
    border-radius: 3px;

    &.noticeSuccess{
      border: 1px solid #7aab6a;
      background: #eeffeb;
      color: #517c43;
    }
    &.noticeInfo{
      border: 1px solid #6a9fab;
      background: #ebfbff;
      color: #436c7c;
    }
  }
  .hasError{

    .form__control{
      border-color: #d64343 !important;
    }
  }
  .errorWrapper{
    background: $white;
    border-radius: 5px;
    padding: 20px;

    .errorCode{
      font-size: 180px;
      margin-bottom: 30px;
      font-weight: 600;
      color: $primary;
    }
    .errorTitle{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .errorSubtitle{
      font-size: 16px;
      color: $textLight;
    }
  }
  .updateSteps{
    margin: 0 0 20px 0;
    padding: 0 0 0 15px;

    li{
      margin-bottom: 5px;
      color: #959595;
      font-size: 14px;
    }
  }

  .updateNotice{
    margin-bottom: 15px;
    color: #959595;
    font-size: 14px;
  }
}

.mainWrapper{
  min-height: 100vh;
  background: $bgLightBlue;
}
.pageWrapper{
  margin-left: $sidebarWidth;
  padding: 0 40px 100px;

  @media screen and ($lg-down) {
    margin-left: $sidebarWidth;
  }
  @media screen and ($xs-down) {
    padding: 0 20px 100px;
  }

  &.sidebarCollapsed{
    margin-left: $sidebarCollapsedWidth;
  }
}
