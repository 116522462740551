@import './src/variables';

.formGroup{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;

  .formControl{
    display: block;
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 5px;
    outline: none;
    position: relative;
    user-select: auto;

    &:focus{
      border-color: #b9bfc4;
    }
  }

  .reactSelectContainer{
    width: 100%;
  }

  textarea{
    resize: none;
    width: 100%;
  }

  .formLabel {
    margin-bottom: 8px;
    font-size: 15px;
    font-family: $overpass;
  }

  select{
    &.formControl{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      background-size: 16px 12px;
      padding: 8px 25px 8px 12px;
      cursor: pointer;
    }
  }

  .customCheckbox{
    display: block;
    min-height: 20px;
    padding-left: 24px;
    margin-bottom: 2px;
    position: relative;
    text-align: left;

    input{
      width: 16px;
      height: 16px;
      vertical-align: top;
      margin-top: 0;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      border: 1px solid rgba(0,0,0,.25);
      appearance: none;
      color-adjust: exact;
      float: left;
      margin-left: -24px;
      border-radius: 3px;
      outline: none;
      transition: background-color .15s ease-in-out,background-position .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

      &:checked{
        background-color: $btnPrimary;
        border-color: $btnPrimary;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
      }

      &:focus{
        box-shadow: 0 0 0 2px rgba(85, 110, 230, .25);
      }
    }

    label{
      cursor: pointer;
      margin-bottom: 0;
      font-weight: 500;
      display: inline-block;
      font-size: 15px;
    }
  }

  .customRadio{
    position: relative;
    text-align: left;
    margin-bottom: 6px;
    padding-left: 24px;

    input {
      width: 16px;
      height: 16px;
      margin-top: 0;
      vertical-align: top;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      border: 1px solid rgba(0,0,0,.25);
      border-radius: 50%;
      appearance: none;
      margin-left: -24px;
      color-adjust: exact;
      outline: none;
      transition: background-color .15s ease-in-out,background-position .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

      &:checked{
        background-color: $btnPrimary;
        border-color: $btnPrimary;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
      }

      &:focus{
        box-shadow: 0 0 0 2px rgba(85, 110, 230, .25);
      }

      label{
        cursor: pointer;
        margin-bottom: 0;
        font-weight: 500;
        display: inline-block;
        font-size: 15px;
      }
    }
  }


  &.singleElement{
    margin: 0;
  }
}

.formError {
  display: inline-block;
  margin-top: 5px;
  font-size: 14px;
  color: #d64343;
}

.tagSwitcher{
  height: 40px;
  width: 50px;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 0 20px rgba(86, 204, 242, 0.05);

  label{
    position: relative;
    padding-left: 40px;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 16px;
      width: 30px;
      border-radius: 10px;
      background: $bgGrey;
    }
    &:after{
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      height: 12px;
      width: 12px;
      border-radius: 10px;
      background: $white;
      transition: all 100ms ease;
    }
  }

  input{
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;

    &:checked{
      & + label:before{
        background: $secondary;
      }
      & + label:after{
        left: 16px;
      }
    }
  }
}

