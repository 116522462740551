@import './src/variables';

.loaderContainer {
  display: flex;
  align-items: center;

  &.sm{
    height: 15px;
  }
  &.md{
    height: 30px;
  }
  &.xl{
    height: 45px;
  }

  .line {
    width: 0.3rem;
    height: 100%;
    margin: 0 0.2rem;
    background: $primary;
    border-radius: 1rem;
    animation: loading 0.8s linear infinite;

    &:nth-child(2){
      animation-delay: 0.1s;
    }

    &:nth-child(3){
      animation-delay: 0.2s;
    }

    &:nth-child(4){
      animation-delay: 0.3s;
    }

    &:nth-child(5){
      animation-delay: 0.4s;
    }

    &:nth-child(6){
      animation-delay: 0.5s;
    }

    &:nth-child(7){
      animation-delay: 0.6s;
    }

    &:nth-child(8){
      animation-delay: 0.7s;
    }
  }
}

@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}