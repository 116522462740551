@import "src/variables";

.card{
  background: $white;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 0 20px rgba(86, 204, 242, 0.05);
  width: 100%;
  min-height: 300px;
  margin-bottom: 30px;

  &.autoHeight{
    min-height: auto;
  }

  .cardTitle{
    margin-bottom: 20px;

    h3{
      font-size: 16px;
      margin: 0;
      font-weight: 600;
    }

    p{
      margin: 10px 0 0;
      color: $textLight;
      font-size: 14px;
    }
  }

  .expandArrow{
    transform: rotate(0deg);
    transition: all .2s ease;
  }

  .faqContent{
    display: none;
    padding: 0 20px;

    li{
      margin-bottom: 5px;
    }

    p{
      margin-bottom: 5px;
    }
  }

  &.faqCard{
    min-height: auto;

    .cardTitle{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;

      h3{
        font-size: 16px;
        margin: 0;
        font-weight: 600;
      }
    }

    &.expanded{

      .cardTitle{
        margin-bottom: 20px;
      }

      .expandArrow{
        transform: rotate(180deg);
      }

      .faqContent{
        display: block;
      }
    }
  }
}