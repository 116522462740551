.entitiesSelector{
  width: 100%;

  .title{
    margin-bottom: 8px;
    font-size: 15px;
  }

  .entitiesList{

    li{
      list-style: none;
      margin: 0 0 10px 0;
      padding: 15px 10px 15px 15px;
      border: 1px solid #ced4da;
      border-radius: 3px;
      cursor: pointer;
      line-height: 1;

      &:focus,
      &:hover{
        background: #fafafa;
      }
    }
  }
}

.popupTableActions{
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableImageCell{

  img{
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}