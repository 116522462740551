// Colors
$primary: #556ee6;
$secondary: #6FCF97;
$white: #ffffff;

$textDark: #333333;
$textLight: #959595;
$textGreen: #6FCF97;
$menuItemTitle: #6a7187;

$gradient: linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%);

$bgPage: #F1F5F9;
$bgLightBlue: #F2F7FA;
$bgGrey: #424242;
$bgLiteGrey: #f3f5f5;
$bgDark: #2a3042;
$bgPrimary: #4daec2;
$bgWhiteTransparent: rgba(255, 255, 255, .1);

// Button colors

$btnPrimary: #556ee6;
$btnPrimaryHover: #485ec4;

$btnSuccess: #34c38f;
$btnSuccessHover: #2ca67a;

$btnWarning: #f1b44c;
$btnWarningHover: #cd9941;

$btnDanger: #f46a6a;
$btnDangerHover: #cf5a5a;

$btnDark: #343a40;
$btnDarkHover: #2c3136;

$btnDefault: #eff2f7;
$btnDefaultHover: #f1f4f8;

// Fonts

$roboto: 'Roboto', sans-serif;
$overpass: 'Overpass', sans-serif;

// Media Queries

$xs-down: 'max-width: 599px';
$xs-up: 'min-width: 600px';

$sm-down: 'max-width: 767px';
$sm-up: 'min-width: 768px';

$md-down: 'max-width: 991px';
$md-up: 'min-width: 992px';

$lg-down: 'max-width: 1199px';
$lg-up: 'min-width: 1200px';

$xl-down: 'max-width: 1399px';
$xl-up: 'min-width: 1400px';

$xxl-down: 'max-width: 1979px';
$xxl-up: 'min-width: 1980px';

// Components


$sidebarWidth: 300px;
$sidebarCollapsedWidth: 80px;

