@import "src/variables";

.tablePagination{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .itemsPerPage{

    select{
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      outline: none;
      position: relative;
      margin-left: 5px;
      height: 30px;
      width: 60px;
      padding: 0 15px 0 10px;
      border-radius: 3px;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 6px center;
      background-size: 10px 15px;
      cursor: pointer;
    }
  }

  .paginationButtons{
    margin-left: auto;
  }

  .btnPagination{
    border-radius: 30px;
    margin: 0 3px;
    border: none;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    padding-left: 2px;
    background: #eff2f7;
    color: $textLight;

    &:first-child{
      padding: 0 2px 0 0;
    }
    &:last-child{
      padding: 0 0 0 2px;
    }

    &:focus,
    &:active{
      box-shadow: 0 0 0 .15rem rgba(85,110,230,.25);
    }

    &.btnDisabled{
      cursor: default;
      background: #fafafa;
      color: #cccccc;

      &:focus,
      &:active{
        box-shadow: none;
      }
    }
  }
}