@import "src/variables";

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: $textDark;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 5px;
  outline: none;
  font-family: $overpass;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

  &.btnDefault{
    color: #000;
    background-color: $btnDefault;
    border-color: $btnDefault;
    &:hover{
      background-color: $btnDefaultHover;
      border-color: $btnDefaultHover;
    }
  }

  &.btnPrimary{
    color: $white;
    background-color: $btnPrimary;
    border-color: $btnPrimary;
    &:hover{
      background-color: $btnPrimaryHover;
      border-color: $btnPrimaryHover;
    }
  }
  &.btnSuccess{
    color: $white;
    background-color: $btnSuccess;
    border-color: $btnSuccess;
    &:hover{
      background-color: $btnSuccessHover;
      border-color: $btnSuccessHover;
    }
  }

  &.btnWarning{
    color: $white;
    background-color: $btnWarning;
    border-color: $btnWarning;
    &:hover{
      background-color: $btnWarningHover;
      border-color: $btnWarningHover;
    }
  }

  &.btnDanger{
    color: $white;
    background-color: $btnDanger;
    border-color: $btnDanger;
    &:hover{
      background-color: $btnDangerHover;
      border-color: $btnDangerHover;
    }
  }

  &.btnDark{
    color: $white;
    background-color: $btnDark;
    border-color: $btnDark;
    &:hover{
      background-color: $btnDarkHover;
      border-color: $btnDarkHover;
    }
  }

  &.btnDisabled{
    pointer-events: none;
    cursor: not-allowed;
    background-color: #cccccc;
    border: 1px solid #cccccc;
  }

  &.btnWhite{
    color: #000;
    background-color: transparent;
  }

  &.btnRounded{
    border-radius: 100px;
  }

  &.hasShadow{
    box-shadow: 0 20px 44px -12px rgba(0,0,0, 0.35);
  }
}