@import './src/variables';

.header{
  padding: 20px 0 30px;
  display: flex;
  align-items: center;

  @media screen and ($xs-down) {
    align-items: start;
    flex-direction: column;
  }
}

.pageTitle{

  @media screen and ($xs-down) {
    margin-bottom: 10px;
  }

  h2{
    font-size: 24px;
    font-weight: 600;
    font-family: $roboto;
    margin: 0;
    line-height: 1.2;
  }
}

.headerItem{
  height: 40px;
  margin-left: auto;
  display: flex;
  align-items: center;

  @media screen and ($xs-down) {
    margin-left: 0;
    justify-content: center;
  }
}

.synchronizeText{
  margin-right: 20px;
  font-size: 14px;

  span{
    @media screen and ($xs-down) {
      display: none;
    }
  }
}

.appSwitcher{
  height: 40px;
  width: 120px;
  background: $white;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 0 20px rgba(86, 204, 242, 0.05);

  label{
    position: relative;
    padding-left: 40px;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 16px;
      width: 30px;
      border-radius: 10px;
      background: $bgGrey;
    }
    &:after{
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      height: 12px;
      width: 12px;
      border-radius: 10px;
      background: $white;
      transition: all 100ms ease;
    }
  }

  input{
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;

    &:checked{
      & + label:before{
        background: $secondary;
      }
      & + label:after{
        left: 16px;
      }
    }
  }
}