@import "src/variables";

.modalOverlay{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  z-index: 50;
  overflow-y: auto;
}
.modal {
  background: #ffffff;
  position: absolute;
  max-width: 600px;
  width: 100%;
  top: 10%;
  left: calc(50% - 300px);
  z-index: 51;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);

  .modalHeader{
    padding: 15px;
    position: relative;

    h3{
      font-size: 20px;
      font-family: $roboto;
      margin: 0;
      text-align: left;
    }

    .modalClose{
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 18px;
      font-weight: 600;
      color: $textLight;
      cursor: pointer;
      transition: all .2s ease;

      &:hover{
        color: $textDark;
      }
    }
  }

  .modalContent{
    padding: 15px;
  }
  .modalFooter{
    padding: 15px;
  }


  &.modalDelete {
    max-width: 500px;
    left: calc(50% - 250px);

    .modalHeader {
      h3 {
        text-align: center;
      }
    }

    .modalFooter{
      padding: 0 0 10px;
    }
  }


}