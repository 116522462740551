@import './src/variables';

.sideNavigation{
  width: $sidebarWidth;
  height: 100vh;
  background: $bgDark;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 30px 20px 20px;
  z-index: 10;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;

  .logo{
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    padding-left: 20px;

    .logoImage{
      width: 40px;
      margin-right: 10px;
    }
    .logoText{
      font-family: $overpass;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      color: $white;
    }
  }

  .navigationGroup{
    margin-bottom: 20px;

    .navigationGroupTitle{
      font-family: $overpass;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 1px;
      color: $menuItemTitle;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: 800;
      padding-left: 20px;
      display: none;
    }

    .navItem{
      list-style: none;
      margin-bottom: 5px;
      position: relative;
      border-radius: 5px;

      &:last-child{
        margin-bottom: 0;
      }

      .navLink{
        color: #a6b0cf;
        display: block;
        width: 100%;
        padding: 10px 10px 10px 20px;
        transition: all 200ms ease;
        border-radius: 5px;
        position: relative;
        height: 40px;

        .navIcon{
          display: inline-block;
          margin-right: 10px;
        }

        .dropdownIcon{
          position: absolute;
          right: 10px;
          top: 11px;

          svg{
            font-size: 14px;
          }
        }

        &:hover{
          color: $white;
        }

        &.active{
          color: $white;
          background: $bgWhiteTransparent;
        }
      }

      .navItemDropdown{
        display: block;
        margin: 0 10px 0 20px;
        padding-bottom: 10px;

        .navLink{
          padding: 10px 10px 10px 30px;
          font-size: 14px;
        }

        .dropdownTitle{
          display: none;
        }

        &.collapsed{
          display: none;
        }
      }

      &.active{
        background: $bgWhiteTransparent;

        .navLink{
          color: $white;
        }

        .navItemDropdown{

          .navItem{

            &:before{
              width: 0;
            }

            .navLink{
              color: $white;

              &:hover{
                color: $white;
              }

              &:before{
                width: 0;
              }
            }

            &.active{
              padding-right: 10px;
              padding-bottom: 5px;

              .navLink {
                color: $textDark;
              }
            }
          }

          &.collapsed{
            display: block;
          }
        }
      }
    }
  }

  .collapseToggle{
    position: absolute;
    width: 40px;
    right: -40px;
    top: 0;
    height: 100%;
    z-index: 0;

    span{
      display: block;
      position: absolute;
      height: 40px;
      width: 20px;
      background: $bgDark;
      left: 0;
      top: calc(50% - 20px);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      opacity: 0;
      transition: all 500ms ease;

      svg{
        position: absolute;
        top: 11px;
        left: 2px;
        color: $white;
      }
    }

    &:hover{
      span{
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &.collapsed{
    max-width: $sidebarCollapsedWidth;

    .logo{
      padding-left: 0;

      .logoImage{
        margin-right: 0;
      }
      .logoText{
        display: none;
      }
    }

    .navigationGroup{

      .navigationGroupTitle{
        display: none;
      }

      .navItem{

        &.active{

          .navItemDropdown{
            display: none;
          }

          &:before{
            width: 0;
          }
        }

        .navLink{
          padding: 10px;
          text-align: center;

          .navIcon{
            margin-right: 0;
            text-align: center;
          }
          .navText{
            display: none;
          }
        }

        &:hover{
          .navItemDropdown{
            display: block;
            position: absolute;
            left: 40px;
            top: 0;
            background: #2a3042;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            width: 180px;
            margin-left: 0;
            padding-bottom: 0;
            transition: all 2s ease;
            z-index: 2;

            .dropdownTitle{
              display: block;
              color: $white;
              padding: 10px;
              font-size: 14px;
              line-height: 1.4;
              background: $bgWhiteTransparent;
            }

            .navLink{
              .navText{
                display: block;
              }
            }

            .collapsed{
              display: none;
            }
          }

          .dropdownIcon{
            display: none;
          }
        }
      }

      .dropdownIcon{
        display: none;
      }
    }
  }
}