@import './src/variables';

.helpCard{
  background: $white;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(86, 204, 242, 0.05);
  width: 100%;
  min-height: 300px;
  margin-bottom: 30px;

  .helpCardBanner{
    width: 100%;

    img{
      width: 100%;
      height: auto;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      vertical-align: middle;
    }
  }

  .helpCardContent{
    padding: 20px;

    p{
      line-height: 1.5;
      font-size: 14px;

      &:first-child{
        margin-bottom: 15px;
      }

      span{
        color: $primary;
        cursor: pointer;
      }
    }
  }
}